/*########## COLOUR PALLETE ##########*/
:root {
    --yellow: #FFDE59;
}


body {
    background-color: black;
    color: white;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

p {
    font-size: 1.25em;
}

a {
    text-decoration: none;
}

.aButton {
    transition: 0.3s all;
}

.aButton:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.yellow {
    font-family: "Poppins", sans-serif;
    color: var(--yellow);
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: -1;
}

.about {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.about img {
    width: 100%;
    height: 100%;
    transition: all 0.3s;
}

.about img:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.devLang {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7.5em;
    height: 7.5em;
}

.background {
    opacity: 100%;
    min-height: 100vh;
    transition: opacity 1s;
    width: 100%;
    position: absolute;
}

.fade {
    opacity: 15%;
    z-index: -1;
}

.background > div {
    position: absolute;
    min-width: 100vw;
    min-height: 100vh;
}

.show {
    background-size: 110vh !important;
}

.hide {
    opacity: 0% !important;
    pointer-events: none;
}

.right {
    background-position: right !important;
}

.backgroundImg {
    background-repeat: no-repeat;
    background-position: center;
    z-index: -2;

    transition: top 1.5s, background-size 1s, background-position 1s, opacity 1.5s;
}

.python {
    background-image: url("./Images/python.png");
    background-size: 80vh;
}

.cs {
    background-image: url("./Images/cs.png");
    background-size: 90vh;
}

.java {
    background-image: url("./Images/java.png");
    background-size: 80vh;
}

.react {
    background-image: url("./Images/react.png");
    background-size: 80vh;
}

.htmlcssjs {
    background-image: url("./Images/htmlcssjs.png");
    background-size: 90vh;
}

.bolts {
    background-image: url("./Images/zeliktricbolts.png");
    background-size: 100vh;
    opacity: 1;
}

.boltsAnim {
    animation: lightningBolts 4s linear;
}

@keyframes lightningBolts {
    0% { opacity: 0; } 

    10% { opacity: 0; }
    11% { opacity: 1; }
    20% { opacity: 0; }
    50% { opacity: 1; }
}

.name {
    background-image: url("./Images/name.png");
    background-repeat: no-repeat;
    background-size: 100vh;
    background-position: center;
    z-index: -1;
    opacity: 1;

    transition: top 1.5s, background-size 1s, background-position 1s, opacity 1.5s;
    animation: lightningName 4s linear;
}

@keyframes lightningName {
    0% { opacity: 0; } 
    22% { opacity: 0; }
    50% { opacity: 1; }
}

.bgImg {
    position: absolute;
    
    width: 50vw;
    
}

.homeText {
    display: flex;
    flex-direction: column;
    width: 75%;

    transition: opacity 1.5s;
}

.homeText p {
    text-align: center;
}

.title {
    font-size: 3em;

    transition: all 0.3s;
    transition-delay: 0.9s;
}

.subtitle {
    font-size: 1.75em;
    text-decoration: underline solid var(--yellow) 1px;

    transition: all 0.3s;
    transition-delay: 0.9s;
}

.title.hide {
    transition-delay: 0s;
}

.subtitle.hide {
    transition-delay: 0s;
}

.zeliktricLogo {
    display: flex;
    position: absolute;
    justify-content: flex-end;

    right: 10em;
}

.nav {
    margin-top: 2.5em !important;
}

.navHome {
    display: flex;
    justify-content: center;
    gap: 2em;
    font-size: 1.25em;

    transition: all 1s;
}

.navLink {
    opacity: 50%;

    transition: opacity 0.3s;
}

.navLink:hover, .navLink:active {
    opacity: 99%;
    cursor: pointer;
}

.navLink:hover .underline, .navLink:active .underline {
    width: 1.5em
}

.underline {
    border-bottom: 2px solid var(--yellow);
    width: 0em;

    transition: width 0.3s;
}

.active {
    opacity: 99%;
}

.active .underline {
    border-bottom: 2px solid var(--yellow);
    width: 1.5em;
}

.info {
    display: flex;
    flex-direction: column;

    position: absolute;
    height: 100%;

    z-index: -1;
    transition: opacity 1s;
}

.info .navHome {
    margin: 3em 3em 0em 3em;
}

.infoContainer {
    display: flex;
    justify-content: center;
    
    height: 100%;
    width: 100%;
    margin: 1em 1em;
}

.infoContainer > div {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 30em;
}

.card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* background-color: rgb(16, 16, 16); */
    border-radius: 5px;
    text-align: center;
}

.card h3 {
    color: var(--yellow);
    text-decoration: underline;
}

.card p {
    text-align: left;
}

.socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    gap: 1em;
    bottom: 1em;
    width: 100%;

    transition: opacity 1.5s;
}

.backButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    gap: 1em;
    bottom: 5em;
    width: 100%;

    transition: opacity 1.5s;
}

.social {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3em;
    height: 3em;
}

.socials img {
    width: 100%;
    height: 100%;
    transition: all 0.3s;
}

.socials img:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid white;
    border-radius: 20%;
}

.projects {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-around;
    align-items: center;
    height: 70%;
    width: 100%;
    position: absolute;

    transition: 0.75s all;
}

/* @media only screen and (min-width: 1024px) and (min-height: 900px) {
    .projects {
        flex-direction: row;
    }
} */

.project {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 25em;

    border-color: var(--yellow) !important;
    border-width: 2.5px !important;
}

.project img {
    width: 70%;
}

.project p {
    margin: 0 1em;
}

.homeButton {
    position: absolute;
    bottom: -2em;
}